@use "@angular/material" as mat;
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import url("https://fonts.googleapis.com/css2?family=Tajawal&display=swap");

@import "./styles-reset.scss";
@import "styles-reset";
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@import "./themes/default-theme";
@import "./themes/light-theme";
@import "./themes/black-theme";
@import "./themes/nature-theme";

@import "styles-reset.scss-theme";

@mixin custom-components-theme($theme) {
  @include anms-styles-reset-theme($theme);
}

.default-theme {
  @include mat.all-legacy-component-themes($anms-theme);
  @include custom-components-theme($anms-theme);
}

.light-theme {
  @include mat.all-legacy-component-themes($anms-light-theme);
  @include custom-components-theme($anms-light-theme);
}

.black-theme {
  @include mat.all-legacy-component-themes($anms-black-theme);
  @include custom-components-theme($anms-black-theme);
}

.nature-theme {
  @include mat.all-legacy-component-themes($anms-nature-theme);
  @include custom-components-theme($anms-nature-theme);
}
:root {
  --myPrimary: #c2185b;
}

*:not(i, mat-icon) {
  font-family: "Tajawal", sans-serif !important;
}

html,
body {
  height: 100%;
}
body {
  direction: rtl;
  margin: 0;
}

.text-right {
  text-align: right;
}

[dir="rtl"] .page-head {
  text-align: right;
}

[dir="ltr"] .page-head {
  text-align: left;
}

.mat-expansion-panel {
  border-radius: 0px !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
  ul {
    li {
      list-style-type: none;
      margin-bottom: 0.5rem;
    }
  }
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}
.mat-select-panel {
  // background: white !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
}
.list-group-item.active {
  background-color: transparent;
  border-color: #5d4037;
  cursor: pointer;
  // color: #5d4037 !important;
}

.list-group-item {
  background-color: transparent;
}
.left-text {
  text-align: left !important;
}

// Add Pages
.add-container {
  border: 1px solid rgba(0, 0, 0, 0.03);
  padding: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
  margin-bottom: 1rem;
  .add-card {
    padding: 3rem 2rem 3rem 2rem;
  }
}

.full-width {
  width: 100%;
}

.container-top-margin {
  margin-top: 2rem;
}
.spacer {
  flex: 1 1 auto;
}

.flex {
  display: flex;
}

.flex-centerd {
  align-items: center;
}

.pointer {
  cursor: pointer;
}

// Data Tables
.table-container {
  margin-bottom: 1rem;
  .table-card {
    margin-top: 2rem;
    padding: 2rem;
  }
}
[dir="rtl"] td.mat-cell {
  text-align: right !important;
}
[dir="rtl"] th.mat-header-cell {
  text-align: right;
  font-weight: bold;
}

.light-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}

//   td, th {
//     width: 25%;
//   }

.green {
  color: green;
}
.red {
  color: red;
}

.filter-input {
  width: 50%;
}

.add-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2.5rem;
    transform: translate(-50%, -50%);
  }
}

.top-margin {
  margin-top: 2rem;
}

:host ::ng-deep .ck-editor__editable_inline {
  min-height: 300px !important;
  background: #303030 !important;
}

.mat-dialog-container {
  // max-height: 93vh !important;
}

.margin-auto {
  margin: auto;
}
.margin-zero {
  margin: 0 !important;
}

.cdk-overlay-pane {
  // width: 90% !important;
}
.no-margin {
  margin: 0;
}
.add-item-row {
  margin-bottom: 1rem;
}

.flex-column {
  flex-direction: column;
}
.cdk-overlay-pane:lang(ar) {
  direction: rtl !important;
}
.upload-thumbnail-id,
#upload-photo,
#upload-thumbnail {
  display: none;
}
#upload-photo-2,
#upload-thumbnail-2 {
  display: none;
}
#upload-photo-3,
#upload-thumbnail-3 {
  display: none;
}
#upload-file,
#upload-fileth {
  display: none;
}
.upload-label {
  background: transparent !important;
  max-height: 270px;
  overflow: hidden;
  i {
    position: absolute;
    top: 0;
    right: 15px;
  }
}

.danger {
  background: #dc3545 !important;
  color: #fff;
}

.success {
  background: #512da8 !important;
  color: #fff;
}

.warning {
  background: #ffc107 !important;
  color: #fff;
}

.mat-tab-body-wrapper {
  padding-top: 2rem;
}

.black-theme .mat-input-element {
  caret-color: #e91e63;
  color: #000 !important;
  border: 1px solid #bbb;
  padding: 1rem;
}

.black-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #000 !important;
}

.close-icon {
  position: absolute;
  right: 1rem;
  top: 0;
}

.mat-expansion-panel-header:not([aria-disabled="true"]) {
  height: 75px !important;
  text-align: right;
}
.mat-content {
  display: block !important;
}
.catss {
  .mat-expansion-panel-body {
    padding: 5px 50px !important;
    // border-top: 1px solid #ccc !important;
  }
}

.default-btn {
  background-color: #004d91 !important;
}

mat-card {
  overflow: auto;
  min-width: 800px;
}
.mat-expansion-panel-header-title p {
  text-align: center;
  white-space: nowrap;
}
.t-a-c {
  text-align: center !important;
}
.cp{
  cursor: pointer;
}
.c-copy{
  cursor: copy;
}
.mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
  height: auto !important;
	min-height: 36px;
}

.uni{
  unicode-bidi: plaintext;
}

.my-spinner {
  margin: 5rem auto;
  width: 3rem !important;
  height: 3rem !important;
}