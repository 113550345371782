@use '@angular/material' as mat;
// @import '.~@angular/material/theming';

@mixin anms-styles-reset-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

  a {
    color: mat.get-color-from-palette($foreground, text) !important;

    &:hover {
      color: mat.get-color-from-palette($accent);
    }
  }

  
  .page-head {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    color: #fff;
    background: mat.get-color-from-palette($primary, lighter) !important;
    i {
      font-size: 2rem;
    }
  }


  table.table th, table.table td {
        color: mat.get-color-from-palette($foreground, text) !important;

        &:hover {
        color: mat.get-color-from-palette($accent);
        }
    }

    .list-group-item {
        color: mat.get-color-from-palette($primary, lighter) !important;
    }

    .mat-select-panel {
        color: mat.get-color-from-palette($foreground, text) !important;
        background: mat.get-color-from-palette($primary, lighter) !important;
    }

  code {
    color: mat.get-color-from-palette($accent, lighter-contrast);
    background-color: mat.get-color-from-palette($accent, lighter);
  }

  p {
    a {
      color: mat.get-color-from-palette($accent);

      &:hover {
        color: mat.get-color-from-palette($accent, darker);
      }
    }
  }
}
