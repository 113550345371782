* {
    &:active,
    :focus {
      outline: none !important;
    }
  }
  
  code {
    white-space: nowrap;
    border-radius: 10px;
    padding: 0 8px 1px 8px;
  }
  
  p {
    a {
      border-bottom: 3px solid;
    }
  }
  
